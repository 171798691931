.about-wrapper {
    padding-top: 15vh;
    margin-bottom: 15vh;
}

h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 3rem;
    color: #dc3545;
}

.about-wrapper h1 {
    font-size: 2.5rem;
    border-bottom: 1px solid lightgray;
    line-height: 0.1em;
    margin-bottom: 15vh;
    width: 100vw;
}

.about-wrapper h1 span {
    background: #FFF;
    padding: 0 50px;
    z-index: -100;
}

.content-wrapper {
    width: 70vw;
    display: flex;
    justify-content: space-between;
}

.content-text {
    text-align: left;
    width: 30vw;
    padding-top: 3vh;
    margin-right: 50px;
}

.content-text p {
    font-size: 17px;
}

.content-text h2 {
    margin-top: 2rem;
}

.about-wrapper .dot {
    padding: 0;
    position: relative;
    border-radius: 50%;
}

.dots-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -30px;
}

.image-container {
    overflow: hidden;
    width: 28vw;
    height: 28vw;
    min-width: 350px;
    min-height: 350px;
    max-width: 500px;
    max-height: 500px;
    margin-right: -100px
}

.image-container img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    object-fit: cover;
}

.about-wrapper .dot-yellow {
    height: 12vw;
    width: 12vw;
    min-height: 100px;
    min-width: 100px;
    background-color: #ffc107;
    z-index: -1;
    top: 0;
    align-self: flex-end;
}

.current-text {
    margin: 2rem 0;
    width: 38vw;
}

.current-text h2 {
    margin-bottom: 1rem;
}

.current-text p {
    padding-left: 2.5rem;
    line-height: 2rem;
    font-size: 1.1rem;
    margin: 0;
}

p span {
    padding: 0;
}

@media screen and (max-width: 480px) {
    h3 {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .about-wrapper h1 {
        border-bottom: none;
        line-height: 1em;
        margin-bottom: 0;
    }

    .about-wrapper h1 span {
        font-size: 26px;
        padding: 0;
    }

    .image-container {
        overflow: hidden;
        width: 300px;
        height: 300px;
        margin: 40px 0;
        left: 0;
    }

    .dots-container {
        left: 0;
        margin-top: 0;
    }

    .dots-container .dot-yellow {
        display: none;
    }

    .content-wrapper {
        width: 90vw;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .content-text {
        text-align: center;
        width: 90vw;
        margin: 0;
    }

    .content-text p {
        font-size: 16px;
    }

    .current-text {
        display: flex;
        width: 90vw;
        flex-direction: column;
        align-items: center;
    }

    .current-text p {
        padding: 0;
    }
}

@media screen and (min-width: 481px) and (max-width: 924px) {

    .about-wrapper h1 {
        border-bottom: none;
        line-height: 1em;
        margin-bottom: 0;
    }

    .about-wrapper h1 span {
        font-size: 28px;
        padding: 0;
    }

    .image-container {
        overflow: hidden;
        width: 300px;
        height: 300px;
        margin: 40px 0;
        left: 0;
    }

    .dots-container {
        left: 0;
        margin-top: 0;
    }

    .dots-container .dot-yellow {
        display: none;
    }

    .content-wrapper {
        width: 80vw;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .content-text {
        text-align: center;
        width: 80vw;
    }

    .current-text {
        margin: 50px 0;
        display: flex;
        width: 80vw;
        flex-direction: column;
        align-items: center;
    }

    .current-text p {
        padding: 0;
        font-size: 20px;
    }
}

@media screen and (min-height: 1024px) and (max-width: 1024px) {

    
    .about-wrapper h1 span {
        font-size: 38px;
    }

    .dots-container {
        left: 0;
    }

    .about-wrapper .dot-yellow {
        top: 200px;
    }

    .content-wrapper {
        align-items: center;
    }

    .content-text {
        padding-top: 0;
    }
}
