#navbar-wrapper {
    width: 90vw;
    height: 13vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#navbar-name {
    font-weight: 600;
    z-index: 2;
}

#sidenav {
    position: fixed;
    right: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

#navlinks-wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#navlinks {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    z-index: 2;
}

#navlinks li {
    margin: 10px 0;
}

#navlinks-background {
    top: -280px;
    left: 0;
    position: absolute;
    height: 280px;
    width: 100vw;
    background-color: white;
    z-index: 1;
    transition: all .5s ease-in-out;
}

#sidenav .vertical-line {
    border-left: 1.8px solid black;
    height: 300px;
    z-index: 1;
    margin-bottom: 1rem;
}

#navbar-wrapper button {
    text-decoration: none;
    padding: 10px;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
    font-size: 12px;
    background-color: transparent;
    outline: none;
    border: 0px;
    cursor: pointer;
}

#navbar-wrapper button p {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    margin: 0;
}

#navbar-wrapper button:hover {
    opacity: 1;
    font-weight: bold;
}

#toggle-menu {
    display: none;
}

@media screen and (max-width: 768px) {

    #navbar-wrapper {
        position: fixed;
        width: 100vw;
        padding-top: 5vh;
        align-items: flex-start;
        z-index: 1;
        background-color: white;
        transition: top 0.3s;
    }

    #sidenav {
        align-items: flex-end;
        position: relative;
        margin-top: 0;
        right: 0;
    }

    #toggle-menu {
        position: relative;
        display: flex;
        justify-content: flex-end;
        z-index: 999;
    }

    #navlinks-wrapper {
        top: -100vh;
        position: relative;
        transition: all .5s ease-in-out;
        text-align: right;
    }

    #navlinks {
        align-items: flex-end;
    }

    #navlinks button {
        padding: 0;
    }

    #navbar-wrapper button p {
        writing-mode: horizontal-tb;
        margin: 0;
        font-size: 16px;
    }
    
    .vertical-line {
        display: none;
    }
}