.header-wrapper {
    width: 70vw;
    padding: 19vh 0 0 0;
    margin-bottom: 15vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.dot {
    position: absolute;
    border-radius: 50%;
}

.dot-blue {
    top: 30vh;
    right: 15vw;
    height: 15vw;
    width: 15vw;
    min-width: 180px;
    min-height: 180px;
    background-color: #007bff;
    z-index: -99;
}

.dot-yellow {
    top: -18vh;
    height: 35vw;
    width: 35vw;
    min-height: 380px;
    min-width: 380px;
    background-color: #ffc107;
    z-index: -100;
}

h1 {
    font-size: 3rem;
    font-weight: bold;
}

h2 {
    font-size: 1.5rem;
}

.description {
  width: 45%;
  overflow: hidden;
}

.heading-wrapper {
    margin: 1.5vh 0 2vh 0;
}

.button {
    margin: 5vh 0;
    padding: 1rem 1.5rem;
    border-radius: 25px;
}

@media screen and (max-width: 480px) {
    #home {
        overflow: hidden;
    }

    h1 {
        font-size: 26px;
    }

    h2 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
    }

    #sidenav {
        margin-top: 0;
    }

    .container-fluid {
        width: 90vw;
    }

    .description {
        width: 100%;
    }

    .dot-yellow{
        width: 150px;
        height: 150px;
        top: 100px;
        left: -20px;
    }

    .dot-blue{
        width: 140px;
        height: 140px;
        top: 330px;
        right: -20px;
    }

    .button {
        margin-top: 80px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 20px;
    }

    p {
        font-size: 18px;
    }

    .container-fluid {
        width: 90vw;
    }

    .description {
        width: 85%;
    }

    .dot-yellow{
        top: 100px;
        right: 40px;
    }

    .dot-blue{
        top: 350px;
        right: 20px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .dot-yellow{
        width: 500px;
        height: 500px;
        top: -30px;
        right: 120px;
    }
}