.work-wrapper {
    padding-top: 15vh;
    margin-bottom: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.work-wrapper h1 {
    font-size: 2.5rem;
    border-bottom: 1px solid lightgray;
    line-height: 0.1em;
    margin-bottom: 15vh;
    width: 100vw;
}

.work-wrapper h1 span {
    background: #FFF;
    padding: 0 50px;
    z-index: -100;
}

.work-grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 58vw;
}

.work {
    width: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.work:last-child {
    margin-bottom: 0;
}

.work-dot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100%;
    position: absolute;
}

.work-dot {
    border-radius: 50%;
    width: 15vh;
    height: 15vh;
    min-height: 120px;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 0;
    top: 0;
    border: 1.5px solid;
    background-color: transparent;
}

.work p {
    margin: 0;
}

.work-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    height: 100%;
    width: 100%;
    margin: 30px 0 30px 130px
}

.work-text-container ul {
    margin-bottom: 0;
}

@media screen and (max-width: 480px) {

    .work-wrapper h1 {
        border-bottom: none;
        line-height: 1em;
        margin-bottom: 40px;
    }

    .work-wrapper h1 span {
        font-size: 26px;
        padding: 0;
    }

    .work-dot-container, .work-text-container ul {
        display: none;
    }

    .work-text-container {
        margin-left: 0;
    }

    .work-text-container h4, .work-text-container p {
        text-align: center;
    }
}

@media screen and (min-width: 481px) and (max-width: 924px) {

    .work-wrapper h1 {
        border-bottom: none;
        line-height: 1em;
        margin-bottom: 40px;
    }

    .work-wrapper h1 span {
        font-size: 28px;
        padding: 0;
    }
}

@media screen and (min-height: 1024px) and (max-width: 1024px) {

    .work-wrapper h1 {
        margin-bottom: 8vh;
    }

    .work-wrapper h1 span {
        font-size: 38px;
    }

    .work-grid {
        width: 65vw;
    }
}
