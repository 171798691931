.footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15vh;
    margin-bottom: 5vh;
}

.credits {
    display: inline;
}

.footer-wrapper p {
    font-size: 0.8rem;
    margin: 0;
}