.project-wrapper {
    width: 68vw;
    padding-top: 15vh;
    margin-bottom: 10vh;
}

.code-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 18vh;
}

.code-wrapper h1 {
    text-align: left;
    width: 65vw;
}

span#code-title {
    padding-right: 50px; 
    background: #FFF;
}

.design-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.design-wrapper h1 {
    text-align: right;
    width: 65vw;
}

span#design-title {
    padding-left: 50px;
    background: #FFF;
}

.project-wrapper h1 {
    font-size: 2.5rem;
    border-bottom: 1px solid lightgray;
    line-height: 0.1em;
    margin-bottom: 10vh;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 47%);
    grid-gap: 1.8rem;
    justify-items: center;
    grid-auto-rows: 40vh;
    width: 100%;
}

.project {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    border: none;
    min-width: 350px;
    max-height: 350px;
    width: 100%;
}

.project:hover .content {
    opacity: 0.95;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease-out;
}

.content {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    padding: 2rem;
    color: white;
}

.content h4 {
    font-weight: bolder;
    font-size: 20px;
}

.content p {
    font-size: 14px;
}

.card-btn {
    width: 120px;
    border-radius: 15px;
    margin-top: 1rem;
}

.project-wrapper img {
    width: 80%;
    height: 80%;
    border-radius: 15px;
    object-fit: cover;
    outline: none;
    box-shadow: 0 0 15px rgb(0, 0, 0, 0.3);
}

.img-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 0;
}

@media screen and (max-width: 480px) {
    h3 {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .code-wrapper h1, .design-wrapper h1 {
        border-bottom: none;
        line-height: 1em;
        margin-bottom: 20px;
    }

    .code-wrapper h1 span, .design-wrapper h1 span {
        font-size: 26px;
        padding: 0;
    }

    .project-wrapper {
        width: 90vw;
    }

    .grid {
        justify-items: start;
        grid-template-columns: repeat(1, 1fr);
    }

    .project {
        min-width: 0;
    }

    .code-wrapper {
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    h3 {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .code-wrapper h1, .design-wrapper h1 {
        border-bottom: none;
        line-height: 1em;
        margin-bottom: 20px;
    }

    .code-wrapper h1 span, .design-wrapper h1 span {
        font-size: 26px;
        padding: 0;
    }

    .project-wrapper {
        width: 90vw;
    }

    .grid {
        justify-items: start;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 50vh;
    }

    .project {
        min-width: 0;
    }

    .code-wrapper {
        margin-bottom: 50px;
    }
}

@media screen and (min-height: 1024px) and (max-width: 1024px) {

    h1 {
        font-size: 38px;
    }

    h3 {
        margin-bottom: 20px;
    }

    .project-wrapper h1 span {
        font-size: 38px;
    }

    .project-wrapper h1 {
        margin-bottom: 40px;
    }

    .code-wrapper {
        margin-bottom: 70px;
    }

    .grid {
        justify-items: start;
        grid-template-columns: repeat(auto-fill, 47%);
        grid-auto-rows: 27vh;
    }
}